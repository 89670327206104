/* Fonts */
body .Loader-page {
  cursor: progress;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  height: 100vh;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  
}
p {
  margin: 0 auto;
  font-size: 18px;
}
body .loader {
  height: 100%;
  margin: auto;
  /* width: 400px; */
  width: 100vw;
  cursor: progress;
  background-color: rgb(34 40 49);
  /* overflow-x: hidden; */
}
a {
  cursor: pointer;
}
body .loader_cogs {
  width: 100px;
  height: 100px;
  top: -120px !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
body .COGfirst {
  position: relative;
  width: 100px;
  height: 100px;
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
  -webkit-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
}
body .COGfirst div:nth-of-type(1) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
body .COGfirst div:nth-of-type(2) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
body .COGfirst div:nth-of-type(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
body .COGfirst div.firstPart {
  width: 100px;
  border-radius: 15px;
  position: absolute;
  height: 100px;
  background: #eeeeee;
  /* background: #9a9ea0; */
}
body .COGfirst div.firstHole {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: rgb(34 40 49);
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
body .COGsecond {
  position: relative;
  width: 80px;
  -webkit-transform: rotate(16deg);
  transform: rotate(16deg);
  top: 28px;
  -webkit-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
  animation: rotate_left 10s 0.1s infinite reverse linear;
  left: -24px;
  height: 80px;
}
body .COGsecond div:nth-of-type(1) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
body .COGsecond div:nth-of-type(2) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
body .COGsecond div:nth-of-type(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
body .COGsecond div.secondPart {
  width: 80px;
  border-radius: 10px;
  position: absolute;
  height: 80px;
  background: #eeeeee;
  /* background: #4f5051; */
}
body .COGsecond div.secondHole {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgb(34 40 49);
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
body .COGthird {
  position: relative;
  width: 60px;
  top: -65px;
  -webkit-transform-origin: 30px 30px;
  transform-origin: 30px 30px;
  -webkit-animation: rotate_left 10.2s 0.4s infinite linear;
  animation: rotate_left 10.2s 0.4s infinite linear;
  -webkit-transform: rotate(4deg);
  transform: rotate(4deg);
  left: 79px;
  height: 60px;
}
body .COGthird div:nth-of-type(1) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
body .COGthird div:nth-of-type(2) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
body .COGthird div:nth-of-type(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
body .COGthird div.thirdPart {
  width: 60px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #f0f, 0 0 40px #0ff,
    0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073; */
  position: absolute;
  height: 60px;
  background: #eeeeee;
}
body .COGthird div.thirdHole {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: rgb(34 40 49);
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* Animations */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate_left {
  from {
    -webkit-transform: rotate(16deg);
    transform: rotate(16deg);
  }
  to {
    -webkit-transform: rotate(376deg);
    transform: rotate(376deg);
  }
}
@keyframes rotate_left {
  from {
    -webkit-transform: rotate(16deg);
    transform: rotate(16deg);
  }
  to {
    -webkit-transform: rotate(376deg);
    transform: rotate(376deg);
  }
}
@-webkit-keyframes rotate_right {
  from {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  to {
    -webkit-transform: rotate(364deg);
    transform: rotate(364deg);
  }
}
@keyframes rotate_right {
  from {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  to {
    -webkit-transform: rotate(364deg);
    transform: rotate(364deg);
  }
}
